/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {

          echo.init({
            offset: 100,
            throttle: 250,
            unload: false,
            callback: function (element, op) {
              console.log(element, 'has been', op + 'ed');
            }
          });
    
    
          var opts = {
            lines: 13, // The number of lines to draw
            length: 5, // The length of each line
            width: 3, // The line thickness
            radius: 10, // The radius of the inner circle
            corners: 1, // Corner roundness (0..1)
            rotate: 0, // The rotation offset
            direction: 1, // 1: clockwise, -1: counterclockwise
            color: '#000', // #rgb or #rrggbb or array of colors
            speed: 1, // Rounds per second
            trail: 60, // Afterglow percentage
            shadow: false, // Whether to render a shadow
            hwaccel: false, // Whether to use hardware acceleration
            className: 'spinner', // The CSS class to assign to the spinner
            zIndex: 2e9, // The z-index (defaults to 2000000000)
            top: '50%', // Top position relative to parent
            left: '50%' // Left position relative to parent
          };
          var target = document.getElementById('loading-screen-inner');
          var spinner = new Spinner(opts).spin(target);
       
        $('#ScrollDown').click(function(){

            $('html, body').animate({
                        scrollTop:  $(this).offset().top + $(this).height()

            }, 500);

           return false; 
        });

        $("a[href*='#']:not([href='#'])").click(function() {
          var scrollheaderheight = 0;
          if (location.pathname.replace(/^\//,'') === this.pathname.replace(/^\//,'') && location.hostname === this.hostname) {
            var target = $(this.hash);
            if(window.matchMedia('(min-width: 949px)').matches){
              scrollheaderheight = $('#main-navigation').height() + 55;
            } else {
              scrollheaderheight = $('#main-navigation').height();
            }
            target = target.length ? target : $('[name=' + this.hash.slice(1) +']');
            if (target.length) {
              $('html,body').animate({
                scrollTop: target.offset().top - scrollheaderheight
              }, 500);
              return false;
            }
          }
        });         
 

        $('.accordion').each(function () {
          var acc = $(this);
          $(acc).find('h3').each(function () {
              $(this).prepend($('<span>').addClass('icon').addClass('icon-plus'));
              
              $(this).click(function (event) {
                  event.preventDefault();
                  var $h3 = $(event.target);
                  var $div = $(this).next('div');
      
                  $(this).find('.icon').toggleClass('icon-plus').toggleClass('icon-minus');
                  $(this).toggleClass('no-border');
      
                  if (!$div.is(':visible')) {
                      $div.slideDown(250);
                  } else {
                      $div.slideUp(150);
                  }
              });
          });
        });


        $('.cycle-accordion').each(function () {
          var acc = $(this);
          var accheight = $(this).height();
          $(acc).find('h3').each(function () {
              $(this).prepend($('<span>').addClass('icon').addClass('icon-plus'));

                  var objHeight = 0;
                  var numDivs = 0;
                  $.each($(this).nextUntil('h3'), function(){
                         objHeight += $(this).height() + 10;
                         numDivs++;
                  });

              $(this).append('(' + numDivs + ')');


              $(this).click(function (event) {
                  event.preventDefault();
                  var $h3 = $(event.target);
                  var $div = $(this).nextUntil('h3');
      
                  $(this).find('.icon').toggleClass('icon-plus').toggleClass('icon-minus');
                  $(this).toggleClass('no-border');
      
                  if (!$div.is(':visible')) {
                      $div.slideDown(250);
                      accheight += objHeight;
                  } else {
                      $div.slideUp(150);
                      accheight -= objHeight;
                  }

                  $(this).parent().parent().parent().parent().css({height:accheight});
              });
          });
        });

        // If cookie-notice cookie is not set, show .new-cookie-notice
        if ((!readCookie('cookie-notice')) && (!$('body').hasClass('iframe'))) {
            $('.new-cookie-notice').show();
        } else {
            $('.new-cookie-notice').hide();
        }
    
        // Add the event that closes the popup and sets the cookie that tells us to
        // not show it again until 3650 days have passed.
        $('#close-cookie-notice').click(function(){
            $(".new-cookie-notice").slideUp(150);
            createCookie('cookie-notice', true, 3650)
            return false;
        });

        function createCookie(name, value, days) {
            if (days) {
                var date = new Date();
                date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
                var expires = "; expires=" + date.toGMTString();
            } else var expires = "";
            document.cookie = name + "=" + value + expires + "; path=/";
        }
        
        function readCookie(name) {
            var nameEQ = name + "=";
            var ca = document.cookie.split(';');
            for (var i = 0; i < ca.length; i++) {
                var c = ca[i];
                while (c.charAt(0) == ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        }
        
        function eraseCookie(name) {
            createCookie(name, "", -1);
        }

        var captchab = {"ajaxurl":"/wp-admin\/admin-ajax.php"};

        $('#contact-form').validate({
            invalidHandler: function(form, validator) {
                $(this).addClass('invalid');
                if (!validator.numberOfInvalids()) {
                    return;
                }
            },
        
            validHandler: function(form, validator) {
                $('html, body').animate({ scrollTop: "0" });
                  $(this).removeClass('invalid');
            },  
            ignore: "",
            rules: { 
                'contact[email]': { 
                    required: true, 
                    email: true
                },

                "contact_hiddenRecaptcha": {
                  required: true
                }
            }, 
            highlight: function(element) {
                $(element).closest(".form-row").addClass("row-error");
                $(element).addClass("input-error");
            },
            unhighlight: function(element) {
                $(element).closest(".form-row").removeClass("row-error");
                $(element).removeClass("input-error");
            },
            errorPlacement: function(error, element) {
                 error.appendTo( element.closest(".input-wrap") );
            },
            submitHandler: function(form) {
              var formData;
              formData = 'action=contactFormAction&' + $(form).serialize();
                $.ajax({
                    url: SiteParameters.ajax_url,
                    type: 'POST',
                    dataType: 'JSON',
                    data: formData,
                    beforeSend: function () {
                      //console.log('beforeSend');               
                    },
                    error: function () {
                      console.log('error function fired');
                    },                    
                    success: function(response) {
                      if (response.status === 'success') {
                        $("#contact-form").fadeOut(10);
                        $(".contact-form-section .section-headline").fadeOut(10);
                        $(".contact-form-section .content-wrap").fadeOut(10);
                        $(".contact-form-section #contact-form-success").fadeIn(10);
                        $('html, body').animate({ scrollTop: $('#form-section-anchor').offset().top - 60 });
                      } else {
                        console.log('success error');
                      }
                    },
                    complete: function() {
                      //console.log('Complete');
                    }            
                });
            }            
        });

        $('#contact-form').ajaxForm(function() {
          //console.log('ajaxForm fired');
          //$("#contact-form").fadeOut(10);
          //$(".contact-form-section .section-headline").fadeOut(10);
          //$(".contact-form-section .content-wrap").fadeOut(10);
          //$(".contact-form-section #contact-form-success").fadeIn(10);
          //$('html, body').animate({ scrollTop: $('#form-section-anchor').offset().top - 60 });
        });               

        $('#popup-form').validate({
            invalidHandler: function(form, validator) {
                $(this).addClass('invalid');
                if (!validator.numberOfInvalids()) {
                    return;
                }
            },
        
            validHandler: function(form, validator) {
                $('html, body').animate({ scrollTop: "0" });
                  $(this).removeClass('invalid');
            },  
            ignore: "",
            rules: { 
                'contact[email]': { 
                    required: true, 
                    email: true
                },

                "popup_hiddenRecaptcha": {
                    required: true
                }
            }, 
            highlight: function(element) {
                $(element).closest(".form-row").addClass("row-error");
                $(element).addClass("input-error");
            },
            unhighlight: function(element) {
                $(element).closest(".form-row").removeClass("row-error");
                $(element).removeClass("input-error");
            },
            errorPlacement: function(error, element) {
                 error.appendTo( element.closest(".input-wrap") );
            },
            submitHandler: function(form) {
              var formData;
              formData = 'action=popupFormAction&' + $(form).serialize();
                $.ajax({
                    url: SiteParameters.ajax_url,
                    type: 'POST',
                    dataType: 'JSON',
                    data: formData,
                    beforeSend: function () {
                      //console.log('beforeSend');               
                    },
                    error: function () {
                      console.log('error function fired');
                    },                    
                    success: function(response) {
                      if (response.status === 'success') {
                        $("#popup-form").fadeOut(10);
                        $("#popup-form-wrap .section-headline").fadeOut(10);
                        $("#popup-form-wrap .content-wrap").fadeOut(10);
                        $("#popup-form-wrap #contact-form-success").fadeIn(10);
                      } else {
                        console.log('success error');
                      }
                    },
                    complete: function() {
                      //console.log('Complete');
                    }            
                });
            }            
        });

        $('#popup-form').ajaxForm(function() {
          //console.log('popup-form fired');
          //$("#popup-form").fadeOut(10);
          //$("#popup-form-wrap .section-headline").fadeOut(10);
          //$("#popup-form-wrap .content-wrap").fadeOut(10);
          //$("#popup-form-wrap #contact-form-success").fadeIn(10);
        });                 
       
      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
/*
          WebFontConfig = {
            google: { families: [ 'Lato:300,400,700,900:latin' ] }
          };
          (function() {
            var wf = document.createElement('script');
            wf.src = 'https://ajax.googleapis.com/ajax/libs/webfont/1/webfont.js';
            wf.type = 'text/javascript';
            wf.async = 'true';
            var s = document.getElementsByTagName('script')[0];
            s.parentNode.insertBefore(wf, s);
          })();*/

      },
      resize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired

        var windowHeight = $(window).height();   
        var headerHeight = $('#main-navigation').height();
        var subNavHeight = $('#sub-nav').height();
        var footerHeight = $('#site-footer').height(); 
        var mainHeight = windowHeight - footerHeight - headerHeight - subNavHeight;
        var fiboelHeight = $('#fi-bo-el').height();

        $('main').css({minHeight:mainHeight});

        if(window.matchMedia('(min-width: 529px)').matches){ 
          $('.fullheight').css({height:windowHeight - headerHeight});
          $('.fullheight .ScrollDown').css({display:'inline-block'});
          $('.mediumheight').css({height:windowHeight - headerHeight - subNavHeight - 75});
          $('.big-number-sync').syncHeight();
          
          if(window.matchMedia('(min-width: 768px)').matches){
            $('.flow-section-col').syncHeight();

            if(window.matchMedia('(min-width: 949px)').matches){
              $('.fullheight').css({height:windowHeight - headerHeight - subNavHeight});
            }

          }          
        } else {
          $('.fullheight').css({height:'auto'});
          $('.fullheight .ScrollDown').css({display:'none'});
          $('.mediumheight').css({height:'auto'});
          $('.big-number-sync').css({minHeight:'auto'});          
          $('.flow-section-col').css({minHeight:'auto'});
        }        

        if (fiboelHeight < windowHeight) {
          $('#fi-bo-el').css({bottom:footerHeight});
          var fixedHeightBottom = fiboelHeight + footerHeight;
          $('#main-container').css({marginBottom:fixedHeightBottom});
        } else {
          $('#fi-bo-el').css({bottom:0});
          $('#fi-bo-el').css({position:'relative'});
          $('#main-container').css({marginBottom:footerHeight});
        }
        
        $('.tile').each(function(){
          var tileWidth = $(this).outerWidth();
          $(this).css({height:tileWidth});
        });
        
        $('.sync .el').syncHeight();

        $('.innovative .col-sm-3 p').syncHeight();
        
        
        
        
        // Video on Home Page
        $('.popup').magnificPopup({
          type: 'iframe',
          iframe: {   
      
          markup: '<div id="video-modal" class="trailer-video">'+
          '<header class="trailer-video-header">'+
          '<div class="video-header-title mfp-title">'+ 
          '</div>'+
          '<div class="video-header-close">'+
          '<button type="button" class="mfp-close">'+
          '<span class="icon-close"></span>'+
          '</button>'+
          '</div>'+
          '</header>'+
          '<section class="trailer-video-content">'+
          '<div class="video-container">'+
          '<iframe class="mfp-iframe" width="1000" height="562" frameborder="0" allowfullscreen></iframe>'+
          '</div>'+
          '</section>'+
          '</div>',
          },
          callbacks: {
            markupParse: function(template, values, item) {
             values.title = item.el.attr('title');
            }
          }
      
        });        

    
        var parentHeight =  $('.video-wrapper').height();
        var parentWidth =  $('.video-wrapper').width();
        
        var videoWidth = $('.video-wrapper video').width();    
        var videoHeight = videoWidth/1.7910;

        //console.log(parentHeight,videoHeight);

        if ($('.video-wrapper').parent().hasClass('header-image-left'))
            parentHeight = videoHeight;

        if(parentHeight < videoHeight){

              $('.header-image .video-wrapper video').css({width:'100%',height:'auto'});  

             // $('.video-wrapper').css({width:'100%',height:'auto'});  

              var offSetTop = -(videoHeight - parentHeight) / 2;

              $('.video-wrapper video').css({top:offSetTop,left:0});

         }
         else {

             $('.header-image .video-wrapper video').css({width:'auto',height:'100%'});

            // $('.video-wrapper').css({width:'auto',height:'100%'});

             var offSetLeft = -(videoWidth - parentWidth) / 2;  

             $('.video-wrapper video').css({top:0,left:offSetLeft});

         }


        $('.popup-with-form').magnificPopup({
          type: 'inline',
          src: '#popup-form-wrap',
          preloader: false,
          closeBtnInside: true,
          focus: '#contact_fullName',
      
          // When elemened is focused, some mobile browsers in some cases zoom in
          // It looks not nice, so we disable it:
          callbacks: {
            beforeOpen: function() {
              if($(window).width() < 950) {
                this.st.focus = false;
              } else {
                this.st.focus = '#contact_fullName';
              }
            }
          }
        });


        if (!$('body').hasClass("page-template-template-layouts")) {
          var fiboelBottom = $('#site-footer').height();
          $('#fi-bo-el').css({bottom:fiboelBottom});
  
          var fixedHeightBottom = $('#fi-bo-el').height() + $('#site-footer').height();
  
          $('#main-container').css({marginBottom:fixedHeightBottom});
          //$('#slideout-container').css({marginBottom:fixedHeightBottom});
        }


        var resizeSliders = null;

        clearTimeout(resizeSliders);
        resizeSliders = setTimeout(
          function() {    
            // Sliders
            function renderCounter(slick,carouselId) {
              var slidesToShow;
              if(slick.activeBreakpoint!==null){
                slidesToShow = slick.breakpointSettings[slick.activeBreakpoint].slidesToShow;
              } else {
                slidesToShow = slick.options.slidesToShow;
              }
    
              if($('#'+carouselId).parents('.slider-wrapper').find('.slick-next').length>0){
                $('#'+carouselId).parents('.slider-wrapper').find('.counter').html( (slick.currentSlide+slidesToShow)/slidesToShow +' / ' + Math.ceil((slick.slideCount)/slidesToShow));
                $('#'+carouselId).parents('.slider-wrapper').find('.slider-control').show();
              } else {
                $('#'+carouselId).parents('.slider-wrapper').find('.counter').html('');
                $('#'+carouselId).parents('.slider-wrapper').find('.slider-control').hide();
              }
            } 
    
            $('.common-slider').each(function (idx, item) {
              if ($(this).hasClass('slick-initialized')) {
                  $(this).slick('resize');
              } else {
                var carouselId = "carousel_" + idx;
                this.id = carouselId;
                $(this).attr('data-counter','carousel_slick-control_' + idx);
                $(this).next('.slider-control').attr('id','carousel_slick-control_' + idx);
                
                $(this).on('init', function(event, slick) {
                  //console.log('init');
                });
    
                $(this).on('setPosition', function(event, slick) {
                  //console.log('setPosition');
                  renderCounter(slick,carouselId);
                });
    
                $(this).on('afterChange', function(event, slick, currentSlide){
                  //console.log('afterChange');
                });            
    
                $(this).slick({
                  infinite: true,
                  slidesToShow: 4,
                  slidesToScroll: 4,
                  rows: 0,
                  appendArrows: $(this).next('.slider-control')
                });
              }
            }); 
          },
          400
        );   

        var resizeHeights = null;

        clearTimeout(resizeHeights);
        resizeHeights = setTimeout(
          function() {
            $('.testimonials-list .testimonials-item-text').syncHeight();
            $('.testimonials-list .testimonials-item').syncHeight();

          },
          500
        );  

      },
      windowload: function() {
          // slideout menu
          if($(window).width()>320) {
              
            var slideout = new Slideout({
              'header': document.getElementById('main-navigation'),
              'panel': document.getElementById('slideout-container'),
              'menu': document.getElementById('slideout-nav'),
              'side': 'left',
              'duration': 300,
              'padding': 337
            });
              
          } else {
            var slideout = new Slideout({
              'header': document.getElementById('main-navigation'),
              'panel': document.getElementById('slideout-container'),
              'menu': document.getElementById('slideout-nav'),
              'side': 'left',
              'duration': 300,
              'padding': 304
            });            
          }

          slideout.on('beforeopen', function() {
            $('html').addClass('slideout-animation');
            $('html').addClass('left-slideout-open');
          });
      
          slideout.on('beforeclose', function() {
            $('html').removeClass('slideout-animation');
            setTimeout(function() {
              $('html').removeClass('left-slideout-open');
            }, this._duration + 50);            
          });                
      
          // Toggle button for slideout menu
          $('.toggle-button').on('click', function() {
              slideout.toggle();
          }); 
                      
          $('#loading-screen').hide();
          $('.header-content h1').fadeIn(1000);   


          // language switcher slideout menu
          if ($( "#language-slideout-nav" ).length) {
            if($(window).width()>320) {
                
              var langslideout = new Slideout({
                'header': document.getElementById('main-navigation'),
                'panel': document.getElementById('slideout-container'),
                'menu': document.getElementById('language-slideout-nav'),
                'side': 'right',
                'duration': 300,
                'padding': 337
              });
                
            } else {
              var langslideout = new Slideout({
                'header': document.getElementById('main-navigation'),
                'panel': document.getElementById('slideout-container'),
                'menu': document.getElementById('language-slideout-nav'),
                'side': 'right',
                'duration': 300,
                'padding': 304
              });            
            }
  
            langslideout.on('beforeopen', function() {
              $('html').addClass('slideout-animation');
              $('html').addClass('right-slideout-open');
            });
        
            langslideout.on('beforeclose', function() {
              $('html').removeClass('slideout-animation');
              setTimeout(function() {
                $('html').removeClass('right-slideout-open');
              }, this._duration + 50);            
            });  
  
            // Toggle button for language switcher    
            if(window.matchMedia('(min-width: 949px)').matches){
              $('.language-picker').on('click', function() {
                $('.language-picker').toggleClass('open');
                $('.language-picker-overlay').toggleClass('open');
              });  
            }
            else {
              $('.language-picker').on('click', function() {
                langslideout.toggle();
              });   
            }   
          }

        var locationTimeOut = null;


          if(window.location.hash) {
            if($('body').hasClass("page-template-template-layouts")) {
              if ($('.jobs-section').length) {
                var fixedHeightBottom =  $('#site-footer').height();
                var fixedHeightTop = 0;
                if($(window).width()>949) {
                  fixedHeightTop = $('#main-navigation').height() + $('.back-link').height() - 2;
                } else {
                  fixedHeightTop = $('#main-navigation').height() - 2;
                }
          
                $('.hide-on-job').hide();
                $('.main section').not('.jobs-section').hide();
                $('.cycle-accordion-wrapper').css({height:'auto'});
                $('#main-container').css({marginBottom:fixedHeightBottom});
                $('.main').css({paddingTop: fixedHeightTop});
                $('.jobs-row').addClass('job-open');  
              } else {              
                clearTimeout(locationTimeOut);
                locationTimeOut = setTimeout(
                  function() {
                    var scrollheaderheight = 0;
                    if(window.matchMedia('(min-width: 949px)').matches){
                      scrollheaderheight = $('#main-navigation').height() + 55;
                    } else {
                      scrollheaderheight = $('#main-navigation').height();
                    }          
                    $('html, body').animate({
                        scrollTop: $(window.location.hash).offset().top  - scrollheaderheight
                    }, 500);
                  },
                  600
                );  
              }
            } else {
              var fixedHeightBottom =  $('#site-footer').height();
              var fixedHeightTop = 0;
              if($(window).width()>949) {
                fixedHeightTop = $('#main-navigation').height() + $('.back-link').height() - 2;
              } else {
                fixedHeightTop = $('#main-navigation').height() - 2;
              }
  
              $('.hide-on-job').hide();
              $('.cycle-accordion-wrapper').css({height:'auto'});
              $('#main-container').css({marginBottom:fixedHeightBottom});
              $('.main').css({paddingTop: fixedHeightTop});
              $('.jobs-row').addClass('job-open');              
            }            

          }

      }
    },
    // Home page
    'home': {
      init: function() {
      
               

      
      },
      finalize: function() {
       
      },
      resize: function() {

      }
    },
    // About us page, note the change from about-us to about_us.
    'about_us': {
      init: function() {
        // JavaScript to be fired on the about us page
      }
    },
    // page-template-template-layouts
    'page_template_template_layouts': {
      init: function() {
        // JavaScript to be fired on the What We Do page

      },
      finalize: function() {
       
      },
      resize: function() {

      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';
 
      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
          
        
          
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
      UTIL.fire('common','resize');
    },
    resizeEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'resize');
      }); 

      UTIL.fire('common','resize');
        
    },
    windowLoadEvents: function() {

      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm, 'windowload');
      });

      UTIL.fire('common','windowload');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);
  $(window).resize(UTIL.resizeEvents);
  $(window).on("load",UTIL.resizeEvents);
  $(window).on("load",UTIL.windowLoadEvents);

})(jQuery); // Fully reference jQuery after this point.
